<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <Header></Header>
    <AppMain></AppMain>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "./components/Footer.vue"; //底
import Header from "./components/Header.vue"; //头
import AppMain from "./components/AppMain.vue"; //中
export default {
  components: { Footer, Header, AppMain },
};
</script>

<style lang="less" scoped></style>
