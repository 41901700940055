import Vue from 'vue';
import 'element-ui/lib/theme-chalk/index.css';
import {
  Tabs,
  TabPane,
  Carousel,
  CarouselItem,
  Loading,
  MessageBox,
  Message,
  Notification,
  Empty,
  progress,
} from 'element-ui';

Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Empty);
Vue.use(progress);
Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
