<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    :class="[
      activeNav == '/dashboard' && !show ? 'header_dashboard' : 'header',
    ]"
  >
    <div class="center">
      <div class="logoImg">
        <!-- 放背景图片 -->
        <img
          :src="activeNav == '/dashboard' && !show?require('@/assets/logo.png'):require('@/assets/logo2.png')"
          :alt="alts"
          class="image"
          title="我的网站"
        />
      </div>
      <div class="topRight">
        <span
          :class="{ active: activeNav === '/dashboard' }"
          @click="navigators('/dashboard')"
          >首页</span
        >
        <span
          :class="{ active: activeNav === '/product' }"
          @click="navigators('/product')"
          >产品中心</span
        >
        <span
          :class="{ active: activeNav === '/solution' }"
          @click="navigators('/solution')"
          >解决方案</span
        >
        <span
          :class="{ active: activeNav === '/technicalSupports' }"
          @click="navigators('/technicalSupports')"
          >技术文献</span
        >
        <span
          :class="{ active: activeNav === '/news' }"
          @click="navigators('/news')"
          >相关资讯</span
        >
        <span
          :class="{ active: activeNav === '/about' }"
          @click="navigators('/about')"
          >关于我们</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener("scroll", this.scrolling);
    this.activeNav = this.routers;
  },
  data() {
    return {
      activeNav: "/dashboard",
      show: false,
      alts: "智能一体式直流充电桩",
    };
  },
  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      if (scrollTop > 0) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    navigators(index) {
      if (index === this.activeNav) return false;
      this.$router.push(index);
    },
  },
  computed: {
    routers() {
      return this.$route.path;
    },
  },
  watch: {
    routers(val) {
      this.activeNav = val;
    },
  },
};
</script>

<style lang="less" scoped>
// 选中页面时,导航栏选中样式
.active {
  color: #00589c;
  border-bottom: 0.133vw solid #00589c;
  font-weight: bold;
}
// 头部照片+导航栏排列,首页和其他页面共用，故提取出来
.center {
  width: 80%;
  max-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1200px) {
  .center{
    width: 90%!important;
  }
}
@media screen and (max-width: 600px) {
  .center{
    width: 95%!important;
  }
}
// 导航栏布局
.topRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    margin-right: 4vw;
    font-size: 1vw;
    padding-bottom: 0.267vw;
    cursor: pointer;
    // height: 1vw;
    // max-height: 1vw;
  }
  span:hover {
    color: #00589c;
    border-bottom: 0.133vw solid #00589c;
    font-weight: bold;
  }
}
// 首页滚动条=0时
.header_dashboard {
  position: fixed;
  top: 0;
  left: 0;
  height: 6.2vw;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: transparent;
  // background-image: url(@/assets/dashboard/首页_01.gif);
  color: #fff !important;
  // color: black !important;
  z-index: 99;
  // 因为颜色问题,故首页滚动条大于0时,导航栏选中的文字样式
  .active {
    color: #fff !important;
    // color: black !important;
    // border-bottom: 0.133vw solid black !important;
    border-bottom: 0.133vw solid #fff !important;
    font-weight: bold;
  }
  // LOGO
  .logoImg {
    width: 10.707vw;
    height: 5vw;
    margin-left: 2.667vw;
    margin-top: 0.8vw;
    .image {
      width: 100%;
    }
  }
}

// 主体样式
.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 6.2vw;
  width: 100vw;
  background-color: #fff;
  display: flex;
  justify-content: center;
  z-index: 99;
  // LOGO
  .logoImg {
    width: 10.707vw;
    height: 5vw;
    margin-left: 2.667vw;
    margin-top: 0.8vw;
    background-size: 100%;
    .image {
      width: 100%;
    }
  }
}

/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #666;
}

/deep/ .el-submenu .el-submenu__title:hover {
  color: #409eff;
}

/deep/.el-menu--horizontal > .el-menu-item {
  color: #666;
}

/deep/.el-menu--horizontal > .el-menu-item:hover {
  color: #409eff;
}
</style>
