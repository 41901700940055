import Vue from 'vue'
import App from './App.vue'
// import ElementUI from 'element-ui';
import axios from "axios";
import '@/icon/iconcss/iconfont.css'
import '@/icon/icons/iconfont.css'
import './plugins/element.js'; // 引入 element.js 文件
import VueLazyload from 'vue-lazyload';//图片懒加载
// Vue.use(ElementUI)
Vue.prototype.$get = get;
Vue.prototype.$post = post;

Vue.config.productionTip = false

import router from './router'

const service = axios.create({
  baseURL: '/api', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000, // request timeout
});

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

function get(url, params = {}, hideLoad) {
  // if(!hideLoad){
  //     window.vm.loading();
  // }
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params: params,
      })
      .then((res) => {
        // !hideLoad && window.vm.loadEnd()
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function post(url, data = {}, hideLoad) {
  // if(!hideLoad){
  //     window.vm.loading();
  // }
  return new Promise((resolve, reject) => {
    service.post(url, data).then(
      (res) => {
        // !hideLoad && window.vm.loadEnd()
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
// 图片懒加载
Vue.use(VueLazyload)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
