import layout from "@/layout";
export default {
  path: "/about",
  title: "走进小芯",
  // 全部页面先显示外层左侧菜单和顶部栏
  component: layout,
  children: [
    {
      // 嵌入中间显示的主要内容
      path: "",
      component: () => import("@/views/about/index"),
    },
  ],
};
