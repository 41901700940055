<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar{
  width: 1px;
  height: 1px;
}
/* body {
  font-family: "Noto Sans SC", "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
} */
/* Windows操作系统下的字体设置 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* 使用Segoe UI字体，如果不可用则使用Arial */
  body {
    font-family: "Microsoft", "YaHei-Regular", "Microsoft YaHei";
  }
}

/* Mac OS X操作系统下的字体设置 */
@media screen and (min-resolution: 2dppx) {
  /* 使用San Francisco字体，如果不可用则使用Helvetica Neue */
  body {
    font-family: "San Francisco", "Helvetica Neue", Arial, sans-serif;
  }
}
</style>
